<template>
  <div class="card card-custom card-stretch gutter-b">
    <template v-if="isLoading">
      <div class="p-4 text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
        ></b-spinner>
      </div>
    </template>
    <template v-else>
      <!--begin::Header-->
      <div class="card-header border-0 pt-3 px-4">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark"
            >Top 5 Avto</span
          >
        </h3>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-2 px-4 pb-0 mt-n3">
        <div class="tab-content" id="myTabTables11">
          <!--begin::Table-->
          <div class="table-responsive">
            <table
              class="
                table
                table-borderless
                table-head-custom
                table-head-bg
                table-vertical-center
              "
            >
              <thead>
                <tr class="text-left">
                  <th class="min-w-150px">Mashina turi</th>
                  <th class="text-right min-w-150px">Davlat raqami</th>
                  <th class="text-right min-w-125px">Yurilgan masofa, km</th>
                  <th class="text-right min-w-125px">
                    Mashinaga xarajat, ming so'm (yoqilg'i, balon va b.)
                  </th>
                  <th class="text-right min-w-125px">
                    Mashinada keltirilgan daromad, ming so'm
                  </th>
                  <th class="text-right min-w-125px">
                    Mashinada keltirilgan foyda, ming so'm
                  </th>
                  <th class="text-right min-w-110px">Avto samaradorligi %</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in top_trucks" :key="index">
                  <td class="">
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        text-hover-primary
                        mb-1
                        font-size-lg
                      "
                      >{{ item.truck_model }}</span
                    >
                  </td>
                  <td class="text-right">
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                      >{{ item.truck_number }}</span
                    >
                  </td>
                  <td class="text-right">
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                      >{{
                        (item.distance && numberWithSpaces(item.distance)) || 0
                      }}</span
                    >
                  </td>

                  <td class="text-right">
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                      >{{
                        (item.total_outcome &&
                          numberWithSpaces(item.total_outcome)) ||
                        0
                      }}</span
                    >
                  </td>
                  <td class="text-right">
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                      >{{
                        (item.total_income &&
                          numberWithSpaces(item.total_income)) ||
                        0
                      }}</span
                    >
                  </td>
                  <td class="text-right">
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                      >{{
                        (item.profit && numberWithSpaces(item.profit)) || 0
                      }}</span
                    >
                  </td>
                  <td class="text-right">
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                      "
                      >{{
                        (item.profit_ratio &&
                          numberWithSpaces(item.profit_ratio)) ||
                        0
                      }}</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!--end::Table-->
        </div>
      </div>
      <!--end::Body-->
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { numberWithSpaces } from '@/utils'

export default {
  created() {
    this.fetchData()
  },

  data() {
    return {
      isLoading: false
    }
  },

  methods: {
    numberWithSpaces,

    async fetchData() {
      this.isLoading = true
      try {
        await this.$store.dispatch('getTopTrucks')
      } catch (error) {
        this.isLoading = false
      }
      this.isLoading = false
    }
  },

  computed: {
    ...mapGetters(['layoutConfig']),
    ...mapState({
      top_trucks: (state) => state.dashboardStore.top_trucks
    })
  }
}
</script>
