<template>
  <div class="col-lg-6 col-xl-4 col-sm-12">
    <div class="card card-custom gutter-b example example-compact">
      <!-- <div class="card-header border-bottom">
        <div class="m-0 card-title font-weight-bold">
          <h3 class="m-0 card-label">Top 5 Qarzdor klientlar, ming so'm</h3>
        </div>
      </div> -->

      <div class="card-header border-bottom">
        <div class="m-0 card-title font-weight-bold">
          <h3 data-chart="6" class="m-0 card-label">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">Top 5 Qarzdor klientlar, ming so'm</span>
              <span class="text-muted mt-3 font-weight-bold font-size-sm">{{date}}</span>
            </h3>
          </h3>
        </div>
      </div>

      <template v-if="isLoading">
        <div class="p-4 text-center">
          <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner" />
        </div>
      </template>

      <template v-else>
        <!-- <pre>{{ top_loaners }}</pre> -->

        <b-card-text>
          <div id="chart-top-loaners">
            <apexchart
              class="d-flex align-items-center justify-content-center"
              type="pie"
              :options="top_loaners.chartOptions"
              :series="top_loaners.series"
            />
          </div>
        </b-card-text>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { numberWithSpaces } from '@/utils'
var today = new Date();
// Date.prototype.getMonthName = function() {
//   var monthNames = [ "January", "February", "March", "April", "May", "June", 
//                        "July", "August", "September", "October", "November", "December" ];
//     return monthNames[this.getMonth()];
// }
// var month_Name = today.getMonthName();
var date = ('01-'+(today.getMonth()+1).toString().padStart(2, 0)+'-'+ today.getFullYear()) +' ' + "holatiga ko'ra";

export default {
  mounted() {
    this.fetchData()
  },

  data() {
    return {
      date,
      isLoading: false
    }
  },

  methods: {
    numberWithSpaces,

    async fetchData() {
      this.isLoading = true
      try {
        await this.$store.dispatch('getTopLoaners')
      } catch (error) {
        this.isLoading = false
      }
      this.isLoading = false
    }
  },

  computed: {
    ...mapState({
      top_loaners: (state) => {
        const top_loaners = state.dashboardStore.top_loaners || []

        return {
          series: top_loaners.map((item) => item.ostatka),
          chartOptions: {
            chart: {
              width: 350,
              height: 200,
              type: 'pie'
            },
            labels: top_loaners.map((item) => item.full_name),
            colors: [
              '#F64E60',
              '#FFA800',
              '#a5a5a5',
              '#3699FF',
              '#ff008aa3',
              '#1BC5BD'
            ],
            yaxis: {
              labels: {
                formatter(val) {
                  return numberWithSpaces(Math.round(val)) || 0
                }
              }
            },
            responsive: [
              {
                breakpoint: 850,
                options: {
                  chart: {
                    width: 350,
                    height: 400
                  },
                  legend: {
                    position: 'bottom',
                    horizontalAlign: 'left'
                  }
                }
              }
            ],

            legend: {
              position: 'bottom',
              horizontalAlign: 'left'
            }
          }
        }
      }
    })
  }
}
</script>

<style scoped>
#chart-top-loaners {
  min-height: 400px;
}

.card.card-custom > .card-header {
  min-height: unset !important;
  padding: 1.5rem;
}
</style>
