<template>
  <div>
    <!--begin::Dashboard-->

    <div>
      <Intersect>
        <truckStatus />
      </Intersect>
    </div>

    <div class="row">
      <!-- Moliyaviy ko'rsatgichlar -->
      <Intersect>
        <div class="col-lg-6 col-xl-4 col-sm-12">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header border-bottom">
              <div class="m-0 card-title font-weight-bold">
                <h3 class="m-0 card-label">
                  Moliyaviy ko'rsatgichlar - {{ currentYear }}
                </h3>
              </div>
            </div>

            <b-card-text>
              <div id="chart">
                <apexchart
                  type="line"
                  :options="gistogramma.chartOptions"
                  :series="gistogramma.series"
                />
              </div>
            </b-card-text>
          </div>
        </div>
      </Intersect>
    </div>

    <div class="row">
      <!-- <div ref="block2" class="col-xl-4 col-lg-6">
        <Intersect>
          <MixedWidget2 />
        </Intersect>
      </div>

      <div ref="block1" class="col-xl-4 col-lg-6">
        <Intersect>
          <MixedWidget1 />
        </Intersect>
      </div> -->

      <div class="col-12">
        <div class="card card-custom example example-compact">
          <div class="card-header border-bottom text-center">
            <div class="m-0 card-title font-weight-bold mx-auto">
              <h3 class="m-0 card-label text-uppercase" data-chart="6">
                Samaradorlik ko'rsatgichlari
              </h3>
            </div>
          </div>
        </div>
      </div>

      <!-- Reyslar samaradorligi -->
      <Intersect>
        <div class="col-lg-6 col-xl-4 col-sm-12">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header border-bottom">
              <div class="m-0 card-title font-weight-bold">
                <h3 class="m-0 card-label">
                  Reyslar samaradorligi - {{ currentYear }}
                </h3>
              </div>
            </div>

            <b-card-text>
              <div id="chart--">
                <apexchart
                  type="line"
                  :options="orders_efficiency.chartOptions"
                  :series="orders_efficiency.series"
                />
              </div>
            </b-card-text>
          </div>
        </div>
      </Intersect>

      <!-- Vazn samaradorligi  -->
      <Intersect>
        <div class="col-lg-6 col-xl-4 col-sm-12">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header border-bottom">
              <div class="m-0 card-title font-weight-bold">
                <h3 class="m-0 card-label" data-chart="5">
                  Vazn samaradorligi - {{ currentYear }}
                </h3>
              </div>
            </div>

            <b-card-text>
              <div id="chart--">
                <apexchart
                  type="line"
                  :options="weight_efficiency.chartOptions"
                  :series="weight_efficiency.series"
                />
              </div>
            </b-card-text>
          </div>
        </div>
      </Intersect>

      <!-- Reyslar va tashilgan yuklar -->
      <Intersect>
        <div class="col-lg-6 col-xl-4 col-sm-12">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header border-bottom">
              <div class="m-0 card-title font-weight-bold">
                <h3 class="m-0 card-label">
                  Reyslar va tashilgan yuklar - {{ currentYear }}
                </h3>
              </div>
            </div>

            <b-card-text>
              <div id="chart--">
                <apexchart
                  type="line"
                  :options="orders_weight_counts.chartOptions"
                  :series="orders_weight_counts.series"
                />
              </div>
            </b-card-text>
          </div>
        </div>
      </Intersect>

      <!-- Masofalar -->
      <Intersect>
        <div class="col-lg-6 col-xl-4 col-sm-12">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header border-bottom">
              <div class="m-0 card-title font-weight-bold">
                <h3 class="m-0 card-label">Masofalar - {{ currentYear }}</h3>
              </div>
            </div>

            <b-card-text>
              <div id="chart--empty_way_ratio">
                <apexchart
                  type="line"
                  :options="empty_way_ratio.chartOptions"
                  :series="empty_way_ratio.series"
                />
              </div>
            </b-card-text>
          </div>
        </div>
      </Intersect>

      <!-- top trucks -->
      <Intersect>
        <div class="col-12">
          <topTrucks />
        </div>
      </Intersect>

      <div class="col-12">
        <div class="card card-custom example example-compact">
          <div class="card-header border-bottom text-center">
            <div class="m-0 card-title font-weight-bold mx-auto">
              <h3 class="m-0 card-label text-uppercase" data-chart="6">
                Klientlar
              </h3>
            </div>
          </div>
        </div>
      </div>

      <!-- Klientlarning debit va kredit qarzdorliklari, ming so'm -->
      <Intersect>
        <div class="col-lg-6 col-xl-4 col-sm-12">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header border-bottom">
              <div class="m-0 card-title font-weight-bold">
                <h3 class="m-0 card-label" data-chart="6">
                  Klientlarning debit va kredit qarzdorliklari, ming so'm -
                  {{ currentYear }}
                </h3>
              </div>
            </div>

            <b-card-text>
              <div id="chart--">
                <apexchart
                  type="line"
                  :options="clients_debet_credit.chartOptions"
                  :series="clients_debet_credit.series"
                />
              </div>
            </b-card-text>
          </div>
        </div>
      </Intersect>

      <Intersect>
        <div class="col-lg-6 col-xl-4 col-sm-12">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header border-bottom">
              <div class="m-0 card-title font-weight-bold">
                <h3 class="m-0 card-label" data-chart="6">
                  Postavshiklarning debit va kredit qarzdorliklari, ming so'm -
                  {{ currentYear }}
                </h3>
              </div>
            </div>

            <b-card-text>
              <div id="chart--">
                <apexchart
                  type="line"
                  :options="providers_debet_credit.chartOptions"
                  :series="providers_debet_credit.series"
                />
              </div>
            </b-card-text>
          </div>
        </div>
      </Intersect>

      <!-- Top Loaners -->
      <Intersect>
        <topLoaners />
      </Intersect>

      <!-- Top 5 Mijoz -->
      <div ref="block4" class="col-sm-6 col-lg-6 col-xl-6">
        <Intersect>
          <ListWidgetTopClient />
        </Intersect>
      </div>

      <div class="col-12">
        <div class="card card-custom example example-compact">
          <div class="card-header border-bottom text-center">
            <div class="m-0 card-title font-weight-bold mx-auto">
              <h3 class="m-0 card-label text-uppercase" data-chart="6">
                Haydovchilar
              </h3>
            </div>
          </div>
        </div>
      </div>

      <!-- Top 5 Haydovchilar -->
      <div ref="block3" class="col-lg-6 col-xl-6">
        <Intersect>
          <ListWidget3 />
        </Intersect>
      </div>

      <!-- 5 -->
      <!-- <Intersect>
        <div ref="block5" class="col-lg-6 col-xl-4">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">Daromadlar</h3>
              </div>
            </div>
            <div class="card-body">
              <div v-for="item in getData" :key="item.id">
                <div class="card-body-hover" style="padding: 0px">
                  <div
                    style="border: 1px solid #e8e8e8"
                    class="card card-custom gutter-b example example-compact"
                  >
                    <div
                      class="card-header card-header-hover d-flex justify-content-between"
                    >
                      <div class="m-0 card-ver__line-wrapper">
                        <span
                          class="bullet bullet-bar align-self-stretch bg-success"
                        ></span>
                        <div class="card-ver__line">
                          <p class="m-0">{{ item.name }}</p>
                          <span>
                            {{ item.code }}
                          </span>
                        </div>
                      </div>

                      <div class="m-0">
                        <p class="m-0">
                          {{
                            Math.round(
                              item.total_income.total / 1000000
                            ).toLocaleString('zh-ZH')
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Intersect> -->

      <!-- 6 -->
      <!-- <Intersect>
        <div ref="block6" class="col-lg-6 col-xl-4">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">Xarajatlar</h3>
              </div>
            </div>
            <div class="card-body">
              <div v-for="item in getMainExp" :key="item.id">
                <div class="card-body-hover" style="padding: 0px">
                  <div
                    style="border: 1px solid #e8e8e8"
                    class="card card-custom gutter-b example example-compact"
                  >
                    <div class="card-header card-header-hover">
                      <div class="m-0 card-ver__line-wrapper">
                        <span
                          class="bullet bullet-bar align-self-stretch bg-danger"
                        ></span>
                        <div class="card-ver__line">
                          <p class="m-0">{{ item.name }}</p>
                          <span>
                            {{ item.code }}
                          </span>
                        </div>
                      </div>

                      <div class="m-0">
                        <p class="m-0">
                          {{
                            Math.round(
                              item.total_expenditure.total / 1000000
                            ).toLocaleString('zh-ZH')
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Intersect> -->

      <!-- Daromadlar va Xarajatlar deprecated -->
      <!-- <Intersect>
        <div ref="block7" class="col-lg-6 col-xl-4">
          <div class="card card-custom gutter-b example example-compact">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">Daromadlar va Xarajatlar</h3>
              </div>
            </div>
            <div class="card-body">
              <apexchart
                class="card-rounded-bottom"
                :options="chartOptions"
                :series="expenditureChart"
                type="area"
              ></apexchart>
            </div>
          </div>
        </div>
      </Intersect> -->

      <!-- Daromadlar va Xarajatlar -->
      <!-- <Intersect>
        <div class="col-lg-6 col-xl-4">
          <b-card no-body class="gutter-b">
            <div
              class="card-header-title h-120 border-bottom"
              :style="{
                backgroundImage:
                  'linear-gradient(to bottom, rgba(0, 0, 0), rgb(0 0 0 / 0%)),url(' +
                  require('@/assets/money2.jpg') +
                  ')'
              }"
            >
              <div
                class="m-0 card-title font-weight-bold text-white"
                style="display: inline-block"
              >
                <h3 class="m-0">Daromadlar va Xarajatlar</h3>
              </div>
            </div>
            <b-tabs pills card>
              <b-tab title="Yil boshidan" active>
                <b-card-text>
                  <div class="d-flex">
                    <div class="col-6 pl-0">
                      <div
                        class="d-flex align-items-center"
                        style="
                          background-color: #f3f6f9;
                          padding: 10px;
                          border-radius: 4px;
                        "
                      >
                        <span class="mr-3">
                          <i class="fas fa-plus-circle text-success"></i>
                        </span>
                        <p
                          class="text-dark-50 m-0 font-weight-bold"
                          v-if="getInfoDashboard.dashboard_info !== undefined"
                        >
                          {{
                            Math.round(
                              getInfoDashboard.dashboard_info.total_income
                                .this_year_income / 1000000
                            ).toLocaleString('uz', {
                              style: 'currency',
                              currency: 'UZS'
                            })
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6 pr-0">
                      <div
                        class="d-flex align-items-center"
                        style="
                          background-color: #f3f6f9;
                          padding: 10px;
                          border-radius: 4px;
                        "
                      >
                        <span class="mr-3">
                          <i class="fas fa-minus-circle text-danger"></i>
                        </span>
                        <p
                          class="text-dark-50 m-0 font-weight-bold"
                          v-if="getInfoDashboard.dashboard_info !== undefined"
                        >
                          {{
                            Math.round(
                              getInfoDashboard.dashboard_info.total_expenditure
                                .this_year_expenditure / 1000000
                            ).toLocaleString('uz', {
                              style: 'currency',
                              currency: 'UZS'
                            })
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
              <b-tab title="O'tkan oy">
                <b-card-text>
                  <div class="d-flex">
                    <div class="col-6 pl-0">
                      <div
                        class="d-flex align-items-center"
                        style="
                          background-color: #f3f6f9;
                          padding: 10px;
                          border-radius: 4px;
                        "
                      >
                        <span class="mr-3">
                          <i class="fas fa-plus-circle text-success"></i>
                        </span>
                        <p
                          class="text-dark-50 m-0 font-weight-bold"
                          v-if="getInfoDashboard.dashboard_info !== undefined"
                        >
                          {{
                            Math.round(
                              getInfoDashboard.dashboard_info.total_income
                                .previous_month_income / 1000000
                            ).toLocaleString('uz', {
                              style: 'currency',
                              currency: 'UZS'
                            })
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6 pr-0">
                      <div
                        class="d-flex align-items-center"
                        style="
                          background-color: #f3f6f9;
                          padding: 10px;
                          border-radius: 4px;
                        "
                      >
                        <span class="mr-3">
                          <i class="fas fa-minus-circle text-danger"></i>
                        </span>
                        <p
                          class="text-dark-50 m-0 font-weight-bold"
                          v-if="getInfoDashboard.dashboard_info !== undefined"
                        >
                          {{
                            Math.round(
                              getInfoDashboard.dashboard_info.total_expenditure
                                .previous_month_expenditure / 1000000
                            ).toLocaleString('uz', {
                              style: 'currency',
                              currency: 'UZS'
                            })
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>

              <b-tab title="Joriy oy">
                <b-card-text>
                  <div class="d-flex">
                    <div class="col-6 pl-0">
                      <div
                        class="d-flex align-items-center"
                        style="
                          background-color: #f3f6f9;
                          padding: 10px;
                          border-radius: 4px;
                        "
                      >
                        <span class="mr-3">
                          <i class="fas fa-plus-circle text-success"></i>
                        </span>
                        <p
                          class="text-dark-50 m-0 font-weight-bold"
                          v-if="getInfoDashboard.dashboard_info !== undefined"
                        >
                          {{
                            Math.round(
                              getInfoDashboard.dashboard_info.total_income
                                .this_month_income / 1000000
                            ).toLocaleString('en', {
                              style: 'currency',
                              currency: 'UZS'
                            })
                          }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6 pr-0">
                      <div
                        class="d-flex align-items-center"
                        style="
                          background-color: #f3f6f9;
                          padding: 10px;
                          border-radius: 4px;
                        "
                      >
                        <span class="mr-3">
                          <i class="fas fa-minus-circle text-danger"></i>
                        </span>
                        <p
                          class="text-dark-50 m-0 font-weight-bold"
                          v-if="getInfoDashboard.dashboard_info !== undefined"
                        >
                          {{
                            Math.round(
                              getInfoDashboard.dashboard_info.total_expenditure
                                .this_month_expenditure / 10000
                            ).toLocaleString('en', {
                              style: 'currency',
                              currency: 'UZS'
                            })
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </b-card-text>
              </b-tab>
            </b-tabs>
          </b-card>
        </div>
      </Intersect> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
// import MixedWidget1 from '@/view/content/widgets/mixed/Widget1.vue'
// import MixedWidget2 from '@/view/content/widgets/mixed/Widget2.vue'

import ListWidget3 from '@/view/content/widgets/list/Widget3.vue'
import topTrucks from '@/view/content/widgets/list/TopTrucksTable.vue'
import topLoaners from '@/view/content/widgets/list/TopLoaners'
import ListWidgetTopClient from '@/view/content/widgets/list/ListWidgetTopClient.vue'
import truckStatus from './dashboardComponents/truckStatus'
import { TimelineLite } from 'gsap'
import monthNames from '@/constants/js/month.names.js'
import { numberWithSpaces } from '@/utils'
const currentYear = new Date().getFullYear()
const currentMonth = new Date().getDate()
import Intersect from 'vue-intersect'

const strokeWidth = {
  line: 4,
  column: 1
}

const chartVars = {
  // 1
  income_fact: {
    yaxisProps: {
      forceNiceScale: true,
      show: true,
      seriesName: 'Savdo fakt',
      axisTicks: {
        show: true
      },
      axisBorder: {
        show: true,
        color: '#70ad46'
      },
      labels: {
        style: {
          colors: '#70ad46'
        },
        formatter(val) {
          return numberWithSpaces(val) || 0
        }
      },
      // title: {
      //   text: 'Income (thousand crores)',
      //   style: {
      //     color: '#008FFB'
      //   }
      // },
      tooltip: {
        enabled: false
      }
    }
  },
  income_plan: {
    yaxisProps: {
      seriesName: 'Savdo reja',
      labels: {
        style: {
          colors: '#4473c5'
        }
      }
    }
  },
  expenditures: {
    yaxisProps: {
      seriesName: 'Xarajatlar',
      labels: {
        style: {
          colors: '#e40808'
        }
      }
    }
  },
  pure_profit: {
    yaxisProps: {
      seriesName: 'Sof foyda',
      labels: {
        style: {
          colors: '#f0c122'
        }
      }
    }
  },

  // 2
  zayavka_weight: {
    yaxisProps: {
      seriesName: 'Jami tashilgan yuk vazni',
      labels: {
        style: {
          colors: '#ed7d31'
        }
      }
    }
  },
  zayavka_counts: {
    yaxisProps: {
      seriesName: 'Reyslar soni',
      labels: { style: { colors: '#4f75bb' } }
    }
  },

  // 3
  empty_way_ratio: {
    client: {
      yaxisProps: {
        seriesName: 'Mijoz uchun yurilgan masofa, km',
        labels: {
          style: { colors: '#4674c2' }
        }
      }
    },
    real: {
      yaxisProps: {
        seriesName: 'Jami yurilgan masofa, km',
        labels: {
          style: { colors: '#ea7c35' }
        }
      }
    },
    ratio: {
      yaxisProps: {
        seriesName: '',
        labels: {
          style: { colors: '#eb0e0c' }
        }
      }
    },
    ratio_percent: {
      yaxisProps: {
        seriesName: "Bo'sh yurish (porojniy), %",
        labels: {
          style: { colors: '#eb0e0c' }
        }
      }
    }
  },

  // 4
  mean_income: {
    yaxisProps: {
      seriesName: "1 reysdan o'rtacha daromad, som",
      labels: { style: { colors: '#08ac53' } }
    }
  },
  mean_expenditure: {
    yaxisProps: {
      seriesName: "1 reysga o'rtacha xarajat, som",
      labels: {
        style: { colors: '#f40806' }
      }
    }
  },
  single_reys_efficiency: {
    yaxisProps: {
      seriesName: '',
      labels: { style: { colors: '#1776b9' } }
    }
  },

  mean_expenditure_percent_amount: {
    yaxisProps: {
      seriesName: '1 reys samaradorligi, %',
      labels: {
        style: { colors: '#1776b9' }
      }
    }
  },

  // 5
  weight_efficiency: {
    mean_income: {
      yaxisProps: {
        seriesName: "1 tonna uchun o'rtacha daromad, so'm/tn",
        labels: { style: { colors: '#08ab55' } }
      }
    },
    mean_expenditure: {
      yaxisProps: {
        seriesName: '',
        labels: { style: { colors: '#f40706' } }
      }
    },
    single_reys_efficiency: {
      yaxisProps: {
        seriesName: '1 tonna samaradorligi, %',
        labels: { style: { colors: '#1374b8' } }
      }
    },
    mean_expenditure_percent_amount: {
      yaxisProps: {
        seriesName: '1 tonna samaradorligi, %',
        labels: {
          style: { colors: '#1374b8' }
        }
      }
    }
  },

  // 6
  clients_debet_credit: {
    clients_credit: {
      yaxisProps: {
        seriesName: "Jami kreditor qarzdorlik, so'm",
        labels: { style: { colors: '#4c71b1' } }
      }
    },
    clients_debet: {
      yaxisProps: {
        seriesName: "Jami debitor qarzdorlik, so'm",
        labels: { style: { colors: '#e27b39' } }
      }
    }
  },

  providers_debet_credit: {
    providers_credit: {
      yaxisProps: {
        seriesName: "Jami kreditor qarzdorlik, so'm",
        labels: { style: { colors: '#4c71b1' } }
      }
    },
    providers_debet: {
      yaxisProps: {
        seriesName: "Jami debitor qarzdorlik, so'm",
        labels: { style: { colors: '#e27b39' } }
      }
    }
  }

  // 7
}

const chartOptionsByKey = (keyStr, forceNiceScale = true) => {
  return {
    chart: {
      height: 350,
      type: 'line',
      stacked: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      width: (keyStr || []).map((item) => strokeWidth[item.type] || 1)
    },
    xaxis: {
      categories: monthNames
    },
    yaxis: {
      forceNiceScale: !!forceNiceScale,
      labels: {
        formatter(val) {
          return numberWithSpaces(val) || 0
        }
      }
    },
    tooltip: {
      fixed: {
        enabled: true,
        position: 'topLeft'
      }
    },
    legend: {
      horizontalAlign: 'left',
      offsetX: 40
    }
  }
}

function tooltipPercentageFormatter(
  value,
  { target_array, reformat_index_list, seriesIndex, dataPointIndex }
) {
  if (reformat_index_list.includes(seriesIndex)) {
    return (
      (target_array[seriesIndex] &&
        target_array[seriesIndex].data &&
        target_array[seriesIndex].data[dataPointIndex]) ||
      value
    )
  }
  return (value && numberWithSpaces(value)) || 0
}

export default {
  name: 'dashboard',
  data() {
    return {
      currentYear,
      currentMonth,

      barChartOptions: {
        chart: {
          height: 350
        }
      },
      series: [
        {
          name: 'Daromad',
          data: [30, 45, 32, 70, 40, 60]
        },
        {
          name: 'Xarajat',
          data: [28, 12, 31, 30, 20]
        }
      ],

      isloaded: {
        gistogramma: false
      }
    }
  },
  components: {
    truckStatus,
    ListWidget3,

    ListWidgetTopClient,

    // MixedWidget1,
    // MixedWidget2,
    topTrucks,
    topLoaners,
    Intersect
  },
  beforeCreate() {
    this.$store.dispatch('getAllDrivers')
    this.$store.dispatch('getIncomesFromService')
    this.$store.dispatch('expenditureIncomeTotal')
    this.$store
      .dispatch('getFinancialData')
      .then(() => (this.isloaded.gistogramma = true))

    this.$store.dispatch('getOrdersWeightCounts')
    this.$store.dispatch('getEmptyWayRatio')
    this.$store.dispatch('getOrdersEfficiency')
    this.$store.dispatch('getWeightEfficiency')
    this.$store.dispatch('getClientsDebetCredit')
    this.$store.dispatch('getProvidersDebetCredit')
    this.$store.dispatch('getTopLoaners')
  },
  created() {
    this.title = this.$t('MENU.ENTERPRICE.PROVIDERS.LIST')
    this.$store.dispatch('getInfoForDashboard')
    this.$store.dispatch('getMainProfitSubCategorySpecialTotal')
  },
  filters: {
    mlnParse(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        // console.log('keldi')
        return Math.round(val / 1000000)
      } else return val
    }
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    ...mapState({
      gistogramma: (state) => {
        const financial_datas = state.dashboardStore.financial_datas || []
        return {
          series: financial_datas.map((item) => ({
            ...item,
            name: chartVars[item.name].yaxisProps.seriesName || item.name,
            color: chartVars[item.name].yaxisProps.labels.style.colors
          })),
          chartOptions: chartOptionsByKey(financial_datas)
        }
      },

      orders_weight_counts: (state) => {
        const orders_weight_counts =
          state.dashboardStore.orders_weight_counts || []

        return {
          series: orders_weight_counts.map((item) => ({
            ...item,
            name: chartVars[item.name].yaxisProps.seriesName || item.name,
            color: chartVars[item.name].yaxisProps.labels.style.colors
          })),
          chartOptions: chartOptionsByKey(orders_weight_counts, false)
        }
      },

      empty_way_ratio: (state) => {
        const empty_way_ratio = state.dashboardStore.empty_way_ratio || []

        // console.log('empty_way_ratio', empty_way_ratio)

        const series = empty_way_ratio
          .map((item) => {
            if (!chartVars.empty_way_ratio[item.name].yaxisProps.seriesName)
              return
            return {
              ...item,
              name: chartVars.empty_way_ratio[item.name].yaxisProps.seriesName,
              color:
                chartVars.empty_way_ratio[item.name].yaxisProps.labels.style
                  .colors,
              var_name: item.name
            }
          })
          .filter((item) => item)

        const options = chartOptionsByKey(empty_way_ratio, false)

        // console.log('series', series)

        return {
          series,
          chartOptions: {
            ...options,
            tooltip: {
              ...options.tooltip,
              y: {
                formatter(value, { series, seriesIndex, dataPointIndex }) {
                  return tooltipPercentageFormatter(value, {
                    reformat_index_list: [2],
                    series,
                    seriesIndex,
                    dataPointIndex,
                    target_array: empty_way_ratio
                  })
                }
              }
            }
          }
        }
      },

      orders_efficiency: (state) => {
        const orders_efficiency = state.dashboardStore.orders_efficiency || []
        const series = orders_efficiency
          .map((item) => {
            if (!chartVars[item.name].yaxisProps.seriesName) return
            return {
              ...item,
              name: chartVars[item.name].yaxisProps.seriesName,
              color: chartVars[item.name].yaxisProps.labels.style.colors,
              var_name: item.name
            }
          })
          .filter((item) => item)

        const options = chartOptionsByKey(orders_efficiency, false) || {}

        return {
          series,
          chartOptions: {
            ...options,
            tooltip: {
              ...options.tooltip,
              y: {
                formatter(value, { series, seriesIndex, dataPointIndex }) {
                  return tooltipPercentageFormatter(value, {
                    reformat_index_list: [2],
                    series,
                    seriesIndex,
                    dataPointIndex,
                    target_array: orders_efficiency
                  })
                }
              }
            }
          }
        }
      },

      weight_efficiency: (state) => {
        const weight_efficiency = state.dashboardStore.weight_efficiency || []
        const series = weight_efficiency
          .map((item) => {
            if (!chartVars[item.name].yaxisProps.seriesName) return
            return {
              ...item,
              name: chartVars[item.name].yaxisProps.seriesName,
              color: chartVars[item.name].yaxisProps.labels.style.colors,
              var_name: item.name
            }
          })
          .filter((item) => item)

        const options = chartOptionsByKey(weight_efficiency, false) || {}

        return {
          series,
          chartOptions: {
            ...options,
            tooltip: {
              ...options.tooltip,
              y: {
                // eslint-disable-next-line no-unused-vars
                formatter(value, { series, seriesIndex, dataPointIndex }) {
                  return tooltipPercentageFormatter(value, {
                    reformat_index_list: [2],
                    series,
                    seriesIndex,
                    dataPointIndex,
                    target_array: weight_efficiency
                  })
                }
              }
            }
          }
        }
      },

      clients_debet_credit: (state) => {
        const clients_debet_credit =
          state.dashboardStore.clients_debet_credit || []

        return {
          series: clients_debet_credit.map((item) => ({
            ...item,
            name:
              chartVars.clients_debet_credit[item.name].yaxisProps.seriesName ||
              item.name,
            color:
              chartVars.clients_debet_credit[item.name].yaxisProps.labels.style
                .colors
          })),
          chartOptions: chartOptionsByKey(clients_debet_credit, false)
        }
      },

      providers_debet_credit: (state) => {
        const providers_debet_credit =
          state.dashboardStore.providers_debet_credit || []

        return {
          series: providers_debet_credit.map((item) => ({
            ...item,
            name:
              chartVars.providers_debet_credit[item.name].yaxisProps.seriesName ||
              item.name,
            color:
              chartVars.providers_debet_credit[item.name].yaxisProps.labels.style
                .colors
          })),
          chartOptions: chartOptionsByKey(providers_debet_credit, false)
        }
      }
    }),
    expenditureChart() {
      let data = this.$store.state.requests.expenditureIncomeTotal
      // console.log('data', data)
      const list = [
        {
          name: 'Daromad',
          data: data.map((x) => Math.round(x.income_total / 1000000)),
          color: '#1BC5BD'
        },
        {
          name: 'Xarajat',
          data: data.map((x) => Math.round(x.expenditure_total / 1000000)),
          color: '#f64e60'
        }
      ]
      return list
    },
    incomesFromService() {
      let data = this.$store.state.requests.incomesFromService
      const list = [
        {
          name: 'Reja',
          data: data.map((x) => Math.round(x.plan_amount / 1000000))
        },
        {
          name: 'Daromad',
          data: data.map((x) => Math.round(x.income_amount / 1000000))
        }
      ]
      return list
    },
    chartOptions() {
      const data = {
        chart: {
          height: 350,
          type: 'area',

          stroke: {
            show: true,
            width: 3,
            // colors: ['#1BC5BD', '#f5c542']
            colors: ['#f5c542', '#1BC5BD']
          },
          curve: 'smooth'
        },
        dataLabels: {
          enabled: false
        },
        fill: {
          type: 'solid',
          opacity: 0
        },
        stroke: {
          curve: 'smooth',
          // colors: ['#f64e60', '#1BC5BD']
          colors: ['#1BC5BD', '#f64e60']
        },
        xaxis: {
          categories: [
            'Yan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Iyn',
            'Iyl',
            'Avg',
            'Sen',
            'Okt',
            'Na',
            'Dek'
          ]
        },
        tooltip: {
          colors: ['#1BC5BD', '#f5c542'],
          style: {
            fontSize: '12px',
            fontFamily: this.layoutConfig('font-family')
          },
          y: {
            formatter: function (val) {
              return val
            }
          },
          marker: {
            show: false
          }
        },
        yaxis: {
          min: 0,
          max: this.findMax(this.expenditureChart) + 20
        }
      }
      return data
    },
    expenditureChartDatas() {
      let data = this.$store.state.requests.expenditureIncomeTotal
      return data.map((x) => {
        const data = x.period.split('-')[2] + '-' + x.period.split('-')[1]
        return data
      })
    },
    getMainExp() {
      return this.$store.state.requests.mainExpenditures
    },
    getInfoDashboard() {
      return this.$store.state.requests.infoForDashboard
    },
    getAllMainExp() {
      return this.$store.state.requests.AllMainExp
    },
    getData() {
      return this.$store.state.requests.mainProfitSubCategorySpecialTotal
    }
  },
  mounted() {
    const timeline = new TimelineLite()
    const { block1 } = this.$refs
    const { block2 } = this.$refs
    const { block3 } = this.$refs
    const { block4 } = this.$refs
    const { block5 } = this.$refs
    const { block6 } = this.$refs
    const { block7 } = this.$refs
    const { block8 } = this.$refs
    timeline.fromTo(
      block1,
      1,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0 },
      0.1
    )
    timeline.fromTo(
      block2,
      1,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0 },
      0.1
    )
    timeline.fromTo(
      block3,
      1,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0 },
      0.1
    )
    timeline.fromTo(
      block4,
      1,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0 },
      0.1
    )
    timeline.fromTo(
      block5,
      1,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0 },
      0.1
    )
    timeline.fromTo(
      block6,
      1,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0 },
      0.1
    )
    timeline.fromTo(
      block7,
      1,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0 },
      0.1
    )
    timeline.fromTo(
      block8,
      1,
      { opacity: 0, y: 100 },
      { opacity: 1, y: 0 },
      0.1
    )

    this.barChartOptions = {
      chart: {
        height: 350,
        id: 'vuechart-example'
      },
      xaxis: {
        categories: [
          'Yan',
          'Feb',
          'Mar',
          'Apr',
          'May',
          'Iyn',
          'Iyl',
          'Avg',
          'Sen',
          'Okt',
          'Na',
          'Dek'
        ]
      }
    }
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t('BREADCRUMBS.HOME')
      }
    ])
  },
  methods: {
    findMax(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) {
        if (
          Math.max.apply(null, val.find((x) => x.name == 'Daromad').data) >
          Math.max.apply(null, val.find((x) => x.name == 'Xarajat').data)
        ) {
          return Math.max.apply(null, val.find((x) => x.name == 'Daromad').data)
        } else {
          return Math.max.apply(null, val.find((x) => x.name == 'Xarajat').data)
        }
      }
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event)
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event)
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]')
      const links = tab.querySelectorAll('.nav-link')
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove('active')
      }

      // set current active tab
      event.target.classList.add('active')

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute('data-tab'))
    }
  }
}
</script>

<style scoped>
.card-header-title.h-120 {
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;
  height: 120px;

  background-position: center;
  background-size: 100%;
}
.tabs >>> .card-header {
  padding: 1rem 2.25rem !important;
}
.card-header-title {
  padding: 1rem 1.25rem;
}
.card-body {
  padding: 1rem 1.25rem !important;
}
.categorLink {
  cursor: pointer;
}
p {
  margin-bottom: 0;
}
.collapse .card-body,
.accordion .card .card-body {
  padding: 0 !important;
}
.card-header-hover:hover {
  background-color: rgba(186, 186, 186, 0.374);
}
.card-body-hover .card-header:focus {
  outline: none;
}
.card.card-custom > .card-header {
  min-height: unset !important;
  padding: 1.5rem;
  font-weight: bold;
}
.card-ver__line-wrapper {
  position: relative;
}
.card-ver__line {
  margin-left: 10px;
}
.bullet {
  top: 0;
  left: 0;
  position: absolute;
  height: 100%;
}
</style>
