var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row",on:{"click":function($event){_vm.isOpen = false}}},[_c('div',{staticClass:"col-3 cursorDashboard",on:{"dblclick":function($event){return _vm.toTrucklistPage('PUS')}}},[_c('div',{ref:"box",staticClass:"card mt-50 card-custom bgi-no-repeat card-stretch gutter-b border-3 border-bottom border-success",style:({
        'background-position': 'right top',
        'background-size': 'contain',
        'background-image': `url(${require('@/assets/svg/11.svg')})`
      })},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"svg-icon svg-icon-2x svg-icon-danger"}),_c('span',{staticClass:"card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"},[_c('countTo',{attrs:{"startVal":_vm.startVal,"endVal":_vm.response.truck_status !== undefined
                ? _vm.response.truck_status.empty
                : 0,"duration":3000}})],1),_c('span',{staticClass:"font-weight-bold text-success font-size-sm truck__status1"},[_vm._v("Bo'sh")])])])]),_c('div',{staticClass:"col-3 cursorDashboard",on:{"dblclick":function($event){return _vm.toTrucklistPage('ORD')}}},[_c('div',{ref:"box2",staticClass:"card mt-50 card-custom bgi-no-repeat card-stretch gutter-b border-3 border-bottom border-primary",style:({
        'background-position': 'right top',
        'background-size': 'contain',
        'background-image': `url(${require('@/assets/svg/service13.svg')})`
      })},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"svg-icon svg-icon-2x svg-icon-danger"}),_c('span',{staticClass:"card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"},[_c('countTo',{attrs:{"startVal":_vm.startVal,"endVal":_vm.response.truck_status !== undefined
                ? _vm.response.truck_status.order
                : 0,"duration":3000}})],1),_c('span',{staticClass:"font-weight-bold text-primary font-size-sm truck__status2"},[_vm._v("Buyurtmada")])])])]),_c('div',{staticClass:"col-3 cursorDashboard",on:{"dblclick":function($event){return _vm.toTrucklistPage('SER')}}},[_c('div',{ref:"box3",staticClass:"card mt-50 card-custom bgi-no-repeat card-stretch gutter-b border-3 border-bottom border-danger",style:({
        'background-position': 'right top',
        'background-size': 'contain',
        'background-image': `url(${require('@/assets/svg/service2.svg')})`
      })},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"},[_c('countTo',{attrs:{"startVal":_vm.startVal,"endVal":_vm.response.truck_status !== undefined
                ? _vm.response.truck_status.service
                : 0,"duration":3000}})],1),_c('span',{staticClass:"font-weight-bold text-danger font-size-sm truck__status3"},[_vm._v("Servisda")])])])]),_c('div',{staticClass:"col-3 cursorDashboard",on:{"dblclick":function($event){return _vm.toTrucklistPage('BRO')}}},[_c('div',{ref:"box4",staticClass:"card mt-50 card-custom bgi-no-repeat card-stretch gutter-b border-3 border-bottom border-warning",style:({
        'background-position': 'right top',
        'background-size': 'contain',
        'background-image': `url(${require('@/assets/svg/service12.svg')})`
      })},[_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"},[_c('countTo',{attrs:{"startVal":_vm.startVal,"endVal":_vm.response.truck_status !== undefined
                ? _vm.response.truck_status.bron
                : 0,"duration":3000}})],1),_c('span',{staticClass:"font-weight-bold text-warning font-size-sm truck__status4"},[_vm._v("Bronda")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }