<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">
        Top 5 Haydovchilar
      </h3>
    </div>
    <div class="card-body pt-2">
      <template>
        <div class="tab-content">
          <div class="table-responsive">
            <table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
              "
            >
              <thead>
                <tr class="text-left">
                  <th class="pl-7">
                    <span>FIO</span>
                  </th>
                  <th class="text-center">Umumiy masofa</th>
                  <th class="text-center">Buyurtma</th>
                  <th class="text-center">Premiya</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in top_drivers" :key="index">
                  <td class="pl-0">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        <span class="symbol-label"
                          ><img
                            :src="require('@/assets/svg/avatars/011-boy-5.svg')"
                            alt=""
                            class="h-75 align-self-end"
                        /></span>
                      </div>
                      <div>
                        <a
                          href="#"
                          class="
                            text-dark-75
                            font-weight-bolder
                            text-hover-primary
                            mb-1
                            font-size-lg
                          "
                          >{{ item.driver }}</a
                        >
                      </div>
                    </div>
                  </td>

                  <td>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                        text-center
                      "
                      >{{ item.total_distance }}</span
                    >
                  </td>

                  <td>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                        text-center
                      "
                      >{{ item.zayavka_count }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                        text-center
                      "
                    >
                      {{
                        (item.premie_total &&
                          numberWithSpaces(item.premie_total)) ||
                        0
                      }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { numberWithSpaces } from '@/utils'

export default {
  name: 'widget-3',
  data() {
    return {
      list: [
        {
          title: 'Ricky Hunt',
          desc: 'PHP, SQLite, Artisan CLI',

          style: 'svg-icon-primary'
        },
        {
          title: 'Anne Clarc',
          desc: 'PHP, SQLite, Artisan CLI',
          svg: 'media/svg/avatars/006-girl-3.svg',
          style: 'svg-icon-warning'
        },
        {
          title: 'Kristaps Zumman',
          desc: 'PHP, SQLite, Artisan CLI',
          svg: require('@/assets/svg/avatars/011-boy-5.svg'),
          style: 'svg-icon-success'
        },
        {
          title: 'Ricky Hunt',
          desc: 'PHP, SQLite, Artisan CLI',
          svg: 'media/svg/avatars/015-boy-6.svg',
          style: 'svg-icon-danger'
        },
        {
          title: 'Carles Puyol',
          desc: 'PHP, SQLite, Artisan CLI',
          svg: 'media/svg/avatars/016-boy-7.svg',
          style: 'svg-icon-info'
        }
      ]
    }
  },
  // components: {
  //   Dropdown2,
  //   Dropdown4
  // },
  async created() {
    // await this.$store.dispatch('getInfoDriverDashboard')
    this.$store.dispatch('getTopDrivers')
  },
  computed: {
    ...mapGetters(['layoutConfig']),
    ...mapState({
      top_drivers: (state) => state.dashboardStore.top_drivers
    })
    // getTopDriver() {
    //   return (this.$store.state.requests.infoDriverDashboard || [])
    //     .slice()
    //     .sort((a, b) => b - a)
    //     .slice(0, 5)
    // }
  },

  methods: {
    numberWithSpaces
  }
}
</script>
