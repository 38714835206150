<template>
  <div>
    <div class="row" @click="isOpen = false">
    <div 
    class="col-3 cursorDashboard"
    @dblclick="toTrucklistPage('PUS')">
      <!--begin::Stats Widget 26-->
      <div
        ref="box"
        class="card mt-50 card-custom bgi-no-repeat card-stretch gutter-b border-3 border-bottom border-success"
        :style="{
          'background-position': 'right top',
          'background-size': 'contain',
          'background-image': `url(${require('@/assets/svg/11.svg')})`
        }"
      >
        <!--begin::ody-->
        <div class="card-body">
          <span class="svg-icon svg-icon-2x svg-icon-danger"> </span>
          <span
            class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
          >
            <countTo
              :startVal="startVal"
              :endVal="
                response.truck_status !== undefined
                  ? response.truck_status.empty
                  : 0
              "
              :duration="3000"
            ></countTo>
          </span>
          <span
            class="font-weight-bold text-success font-size-sm truck__status1"
            >Bo'sh</span
          >
        </div>
        <!--end::Body-->
      </div>
      <!--end::Stats Widget 26-->
    </div>
    <div 
    class="col-3 cursorDashboard"
     @dblclick="toTrucklistPage('ORD')">
      <!--begin::Stats Widget 26-->
      <div
        ref="box2"
        class="card mt-50 card-custom bgi-no-repeat card-stretch gutter-b border-3 border-bottom border-primary"
        :style="{
          'background-position': 'right top',
          'background-size': 'contain',
          'background-image': `url(${require('@/assets/svg/service13.svg')})`
        }"
      >
        <!--begin::ody-->
        <div class="card-body">
          <span class="svg-icon svg-icon-2x svg-icon-danger"> </span>
          <span
            class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
          >
            <countTo
              :startVal="startVal"
              :endVal="
                response.truck_status !== undefined
                  ? response.truck_status.order
                  : 0
              "
              :duration="3000"
            ></countTo>
          </span>
          <span
            class="font-weight-bold text-primary font-size-sm truck__status2"
            >Buyurtmada</span
          >
        </div>
        <!--end::Body-->
      </div>
      <!--end::Stats Widget 26-->
    </div>
    <div 
    class="col-3 cursorDashboard"
     @dblclick="toTrucklistPage('SER')">
      <!--begin::Stats Widget 27-->
      <div
        ref="box3"
        class="card mt-50 card-custom bgi-no-repeat card-stretch gutter-b border-3 border-bottom border-danger"
        :style="{
          'background-position': 'right top',
          'background-size': 'contain',
          'background-image': `url(${require('@/assets/svg/service2.svg')})`
        }"
      >
        <!--begin::Body-->
        <div class="card-body">
          <span
            class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
          >
            <countTo
              :startVal="startVal"
              :endVal="
                response.truck_status !== undefined
                  ? response.truck_status.service
                  : 0
              "
              :duration="3000"
            ></countTo>
          </span>

          <span class="font-weight-bold text-danger font-size-sm truck__status3"
            >Servisda</span
          >
        </div>
        <!--end::Body-->
      </div>
      <!--end::Stats Widget 27-->
    </div>
    <div 
    class="col-3 cursorDashboard"
    @dblclick="toTrucklistPage('BRO')">
      <!--begin::Stats Widget 28-->
      <div
        ref="box4"
        class="card mt-50 card-custom bgi-no-repeat card-stretch gutter-b border-3 border-bottom border-warning"
        :style="{
          'background-position': 'right top',
          'background-size': 'contain',
          'background-image': `url(${require('@/assets/svg/service12.svg')})`
        }"
      >
        <!--begin::Body-->
        <div class="card-body">
          <span
            class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"
          >
            <countTo
              :startVal="startVal"
              :endVal="
                response.truck_status !== undefined
                  ? response.truck_status.bron
                  : 0
              "
              :duration="3000"
            ></countTo>
          </span>
          <span
            class="font-weight-bold text-warning font-size-sm truck__status4"
            >Bronda</span
          >
        </div>
        <!--end::Body-->
      </div>
      <!--end::Stat: Widget 28-->
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { TimelineLite } from 'gsap'
import countTo from 'vue-count-to'
export default {
  components: { countTo },
  data() {
    return {
      startVal: 0,
      response: {},
    }
  },
  created() {
    this.sendRequest()
  },
  methods: {
    async sendRequest() {
      this.response = await axios
        .get('trucks/dashboard/truckstatuses/')
        .then((res) => {
          return res.data
        })
    },
    toTrucklistPage(val){
      window.open(`/toTruckListPage?key=${val}`, '_blank');
    }
  },
  async mounted() {
    const { box } = this.$refs
    const { box2 } = this.$refs
    const { box3 } = this.$refs
    const { box4 } = this.$refs
    const timeline = new TimelineLite()
    timeline.fromTo(box, 1, { opacity: 0, y: 50 }, { opacity: 1, y: 0 }, 0.1)
    timeline.fromTo(box2, 1, { opacity: 0, y: 50 }, { opacity: 1, y: 0 }, 0.2)
    timeline.fromTo(box3, 1, { opacity: 0, y: 50 }, { opacity: 1, y: 0 }, 0.3)
    timeline.fromTo(box4, 1, { opacity: 0, y: 50 }, { opacity: 1, y: 0 }, 0.4)
  }
}
</script>
<style scoped>
.truck__status {
  position: relative;
}
.truck__status::after {
  position: absolute;
  bottom: -5px;
  left: 0;
  border-radius: 10px;
  content: '';
  display: block;
  width: 100%;
  height: 4px;
}
/* .mt-50 {
  margin-top: 50px;
} */
/* .truck__status:nth-child(1)::after {
  background-color: red;
}
.truck__status:nth-child(2)::after {
  background-color: rgb(91, 2, 215);
}
.truck__status:nth-child(3)::after {
  background-color: rgb(31, 184, 148);
}
.truck__status:nth-child(4)::after {
  background-color: rgb(24, 18, 18);
} */
.cursorDashboard{
  cursor: pointer;
}
</style>
