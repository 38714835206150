<template>
  <!--begin::List Widget 4-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0">
      <h3 class="card-title font-weight-bolder text-dark">Top 5 Mijoz</h3>
      <!-- <div class="card-toolbar">
        <Dropdown4 :btnClass="'btn-light-primary'"></Dropdown4>
      </div> -->
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-2">
      <template>
        <div class="tab-content">
          <div class="table-responsive">
            <table
              class="
                table
                table-head-custom
                table-vertical-center
                table-head-bg
                table-borderless
              "
            >
              <thead>
                <tr class="text-left">
                  <th class="pl-7">
                    <span>Nomi</span>
                  </th>
                  <th class="text-center">Buyurtma</th>
                  <th class="text-center">Jami summa</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in getTopClient" :key="item.id">
                  <td class="pl-0">
                    <div class="d-flex align-items-center">
                      <div class="symbol symbol-50 symbol-light mr-4">
                        <span class="symbol-label"
                          ><img
                            :src="require('@/assets/svg/avatars/011-boy-5.svg')"
                            alt=""
                            class="h-75 align-self-end"
                        /></span>
                      </div>
                      <div>
                        <a
                          href="#"
                          class="
                            text-dark-75
                            font-weight-bolder
                            text-hover-primary
                            mb-1
                            font-size-lg
                          "
                          >{{ item.contract__client__full_name }}</a
                        >
                      </div>
                    </div>
                  </td>
                  <td>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                        text-center
                      "
                      >{{
                        (item.id__count && numberWithSpaces(item.id__count)) || 0
                      }}</span
                    >
                  </td>
                  <td>
                    <span
                      class="
                        text-dark-75
                        font-weight-bolder
                        d-block
                        font-size-lg
                        text-center
                      "
                    >
                      {{
                        (item.price_with_nds__sum &&
                          numberWithSpaces(Math.round(item.price_with_nds__sum))) ||
                        0
                      }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- <div class="d-flex align-items-center mb-10" v-bind:key="i"> -->

        <!-- <div class="symbol symbol-40 symbol-light-success mr-5">
            <span class="symbol-label">
              <inline-svg
                src="media/svg/avatars/011-boy-5.svg"
                class="h-75 align-self-end"
              ></inline-svg>
            </span>
          </div>
          <div class="d-flex flex-column flex-grow-1 font-weight-bold">
            <p class="m-0">
              {{ item.staff_fullname }}
            </p>
          </div> -->
        <!-- <div>
            <p class="m-0 text-primary font-weight-bold font-size-h4">
              {{ item.count }}
            </p>
          </div> -->
        <!--end::Symbol-->
        <!--begin::Text-->
        <!--end::Text-->
        <!--begin::Dropdown-->
        <!-- <Dropdown2></Dropdown2> -->
        <!--end::Dropdown-->
        <!-- </div> -->
        <!--end::Item-->
      </template>
    </div>
    <!--end::Body-->
  </div>
  <!--end: List Widget 4-->
</template>

<script>
// import Dropdown2 from '@/view/content/dropdown/Dropdown2.vue'
// import Dropdown4 from '@/view/content/dropdown/Dropdown4.vue'
import { mapGetters } from 'vuex'
import { numberWithSpaces } from '@/utils'

export default {
  name: 'widget-3',
  data() {
    return {}
  },
  // components: {
  //   Dropdown2,
  //   Dropdown4
  // },
  async created() {
    await this.$store.dispatch('getTopClientDashboard')
  },
  methods: { numberWithSpaces },
  computed: {
    ...mapGetters(['layoutConfig']),
    getTopClient() {
      const data = this.$store.state.requests.topClientDashboard
      return data.sort((a, b) => b - a).slice(0, 5)
    }
  }
}
</script>
